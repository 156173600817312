<template>
	<ul class="additional-offers__list">
		<li
			class="additional-offers__item"
			:style="{background: background}"
		>
			<router-link :to="`/${value}`">
				<div class="additional-offers__text">
					<h3 class="additional-offers__title">{{ name }}</h3>
					<p class="additional-offers__time">{{ deliveryTime?.[value] }}</p>
				</div>
				<span>Смотреть меню <img
					src="@/assets/img/arrowBlack.svg"
					alt=""
				></span>
				<div class="additional-offers__bg">
					<img
						:src="cover"
						alt="Meal"
					>
				</div>
			</router-link>
		</li>
	</ul>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "AdditionalOffers",
	props:
		{
			value: {
				type: String,
				default: ''
			},
			name: {
				type: String,
				default: ''
			},
			cover: {
				type: String,
				default: require("@/assets/img/breakfast.webp")
			},
			background: {
				type: String,
				default: "linear-gradient(142.91deg, #E2FCFF 20.64%, #94ECFF 77.6%), #D9D9D9"
			}
		},
	computed:
		{
			...mapGetters({
				deliveryTime: "restaurant/getRestDeliveryTime",
			})
		},
	watch:
		{
			"$store.state.currentRestarauntId"()
			{
				this.$store.dispatch("restaurant/getRestDeliveryTime");
			},
		},
	mounted()
	{
		this.$store.dispatch("restaurant/getRestDeliveryTime");
	}
};
</script>

<style lang="scss">
.additional-offers__list
{
	display: flex;
	flex-wrap: nowrap;
	flex-grow: 1;
	gap: 16px;
	overflow: hidden;
	overflow-x: auto;
	scrollbar-width: none;

	&::-webkit-scrollbar
	{
		height: 0;
	}
}

.additional-offers__item
{
	flex: 0 0 49%;
	flex-grow: 1;
	position: relative;
	padding: 20px 0 24px 24px;
	background: linear-gradient(142.91deg, #E2FCFF 20.64%, #94ECFF 77.6%), #D9D9D9;
	border-radius: 24px;

	span
	{
		display: flex;
		position: relative;
		width: fit-content;
		align-items: center;
		gap: 4px;
		font-weight: 700;
		font-size: 14px;
		line-height: 20px;
		color: #3D4248;
		z-index: 2;

		img
		{
			width: 24px;
			height: 24px;
		}
	}
}

.additional-offers__text
{margin-bottom: 33px;}

.additional-offers__title
{
	text-align: left;
	margin-bottom: 3px;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: -0.5px;
	color: #3D4248;
}

.additional-offers__time
{
	position: relative;
	z-index: 2;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #979797;
}

.additional-offers__bg
{
	width: fit-content;
	height: 100%;
	display: grid;
	place-items: center;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
}

@media (max-width: 1092px)
{
	.additional-offers__item
	{
		flex: 0 0 100%;
	}
}
</style>
