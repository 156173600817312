<template>
	<main class="chat-page__container">
		<iframe
			class="chat-page__jivo"
			:class="{'chat-page__mobile-jivo': mobileCheck('mobileApp')}"
			src="https://jivo.chat/8jXqAGkBpd"
			height="100%"
			width="100%"
		></iframe>
		<BottomNavigation/>
	</main>
</template>

<script>
import BottomNavigation from "@/components/nav/BottomNavigation.vue";
import {mobileCheck} from "@/helpers";

export default {
	name: "ChatPage",
	components: {BottomNavigation},
	methods: {
		mobileCheck
	},
	mounted()
	{
		const currentRestarauntId = this.$cookie.get("restid");
		if ((currentRestarauntId !== '7242' && currentRestarauntId !== '16338') || window.innerWidth >= 1000) this.$router.push("/");
	}
}
</script>

<style lang="scss">
.chat-page__container
{background-color: #fff;}

.chat-page__jivo
{
	width: 100%;
	height: 100svh;
	padding-bottom: 64px;
	border: none;
}

.chat-page__mobile-jivo
{padding-bottom: 8px}
</style>